import styled, { css } from 'styled-components';
import { ThemeTypes } from 'types/Theme.namespace';
import { media } from 'helpers/breakpoints';

/** @deprecated replaced by Spacing */
export interface ISpace {
  marginBottom?: ThemeTypes.Spacing | ThemeTypes.Spacing[];
}
/** @deprecated replaced by spacing */
export const space = css<{
  marginBottom?: ThemeTypes.Spacing | ThemeTypes.Spacing[];
}>`
  ${({ theme, marginBottom }) =>
    marginBottom &&
    (!Array.isArray(marginBottom)
      ? `margin-bottom: ${theme.spacing[marginBottom]}`
      : `margin-bottom: ${theme.spacing[marginBottom[0]]}; ${
          media.medium
        }{margin-bottom: ${theme.spacing[marginBottom[1] ?? marginBottom[0]]}}${
          media.large
        }{margin-bottom: ${
          theme.spacing[marginBottom[2] ?? marginBottom[1] ?? marginBottom[0]]
        }}`)};
`;

export type Spacing = {
  /** margin-bottom
   * ```tsx
   * <Component mb="M24" />
   * <Component mb={["M16", "M24"]} />
   * ```
   */
  mb?: ThemeTypes.Spacing | ThemeTypes.Spacing[];
};

export const spacing = css<{
  mb?: ThemeTypes.Spacing | ThemeTypes.Spacing[];
}>`
  ${({ theme, mb }) =>
    mb &&
    (!Array.isArray(mb)
      ? `margin-bottom: ${theme.spacing[mb]}`
      : `margin-bottom: ${theme.spacing[mb[0]]}; ${
          media.medium
        }{margin-bottom: ${theme.spacing[mb[1] ?? mb[0]]}}${
          media.large
        }{margin-bottom: ${theme.spacing[mb[2] ?? mb[1] ?? mb[0]]}}`)};
`;

export const Spacer = styled.div`
  ${space};
`;
