import { accountsApi } from 'api/accountsApi';
import { isProtectedRoute } from 'constants/protectedRoutes';
import { fireToast } from 'helpers/Toasts';

async function userLogout(dispatchCallback: any, userLoggingOut: string) {
  try {
    await accountsApi.logout();

    dispatchCallback({
      type: 'CLEAR',
    });

    if (isProtectedRoute(location.pathname)) {
      location.assign('/');
    } else {
      fireToast({
        type: 'SUCCESS',
        text: `You're now logged out ${userLoggingOut}. Hope to see you soon!`,
      });
    }
  } catch (_error) {
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later',
    });
  }
}

export { userLogout };
