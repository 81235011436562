import { createContext, useContext, useState, ReactNode } from 'react';
import { Navigation } from 'domains/Navigation';

interface GlobalProviderProps {
  children: ReactNode;
  navigation: Navigation[];
}

interface GlobalContextI {
  isGlobalSearchModalOpen: boolean;
  setIsGlobalSearchModalOpen: (t: boolean) => void;
  navigation: Navigation[];
}

const GlobalContext = createContext<GlobalContextI>({
  isGlobalSearchModalOpen: false,
  setIsGlobalSearchModalOpen: () => null,
  navigation: [],
});

function GlobalProvider({ children, navigation }: GlobalProviderProps) {
  const [isGlobalSearchModalOpen, setIsGlobalSearchModalOpen] =
    useState<boolean>(false);

  return (
    <GlobalContext.Provider
      value={{
        isGlobalSearchModalOpen,
        setIsGlobalSearchModalOpen,
        navigation,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext, GlobalContext };
