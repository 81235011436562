import type { User } from 'types';

type UPDATE = 'UPDATE';
type CLEAR = 'CLEAR';

type UPDATE_ACTION = { type: UPDATE; payload: User };
type CLEAR_ACTION = { type: CLEAR };

export type UserReducerAction = UPDATE_ACTION | CLEAR_ACTION;

function userReducer(user: User | undefined, action: UserReducerAction) {
  switch (action.type) {
    case 'UPDATE':
      return { ...user, ...action.payload };
    case 'CLEAR':
      return undefined;
    default:
      return user;
  }
}

export { userReducer };
