import type { ReactNode } from 'react';

import { ThemeTypes } from 'types/Theme.namespace';
import { Spacing } from 'helpers/genericStyles';

export type Intent = 'INFO' | 'SUCCESS' | 'ERROR' | 'PLAIN';
export type ColorSet = Record<Intent, ThemeTypes.Colors>;

export const containerColorSet: ColorSet = {
  INFO: 'BLUE_LIGHTER',
  SUCCESS: 'GREEN_LIGHTER',
  ERROR: 'RED_LIGHTER',
  PLAIN: 'GREY_LIGHTER',
};

export const iconColorSet: ColorSet = {
  INFO: 'BLUE',
  SUCCESS: 'GREEN',
  ERROR: 'RED',
  PLAIN: 'GREY',
};

export type NotificationProps = Spacing & {
  intent: Intent;
  children: ReactNode;
  hasIcon?: boolean;
  iconOverride?: string;
  className?: string;
  isClosable?: boolean;
  onClose?: () => void;
};
