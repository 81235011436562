import styled from 'styled-components';

import { LinkCSS } from 'components/Toolkit/Button/SharedLink';

import type { LinkProps } from 'components/Toolkit/Button/SharedLink';

const SLinkButton = styled.a<LinkProps>`
  ${LinkCSS}
`;

/**
 * Our link button styles as links.
 */
function Link({
  ofType = 'PRIMARY',
  colorVariant,
  isFakeLink,
  fontSize = 'M14',
  NextLink,
  href,
  children,
  inline,
  shallow = false,
  underline,
  disabled,
  ...rest
}: LinkProps) {
  return (
    <>
      {NextLink ? (
        <NextLink
          href={href as string}
          passHref
          shallow={shallow}
          legacyBehavior
        >
          <SLinkButton
            ofType={ofType}
            as="a"
            fontSize={fontSize}
            colorVariant={colorVariant}
            inline={inline}
            underline={underline}
            disabled={disabled}
            aria-disabled={disabled}
            {...rest}
          >
            {children}
          </SLinkButton>
        </NextLink>
      ) : (
        <SLinkButton
          ofType={ofType}
          href={href}
          as={!isFakeLink ? 'a' : inline ? 'button' : 'div'}
          fontSize={fontSize}
          colorVariant={colorVariant}
          inline={inline}
          underline={underline}
          disabled={disabled}
          aria-disabled={disabled}
          {...rest}
        >
          {children}
        </SLinkButton>
      )}
    </>
  );
}

export { Link };
