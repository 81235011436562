import { DefaultTheme } from 'styled-components';

export const withOpacity = (color: string, opacity?: number) =>
  `${color}${Math.floor(opacity ? opacity * 255 : 0.48 * 255).toString(16)}`;

export function convertHexToRgb(hex: string): {
  r: number;
  g: number;
  b: number;
} {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
}

export function getWindowInnerHeight() {
  return `${window.innerHeight.toString()}px`;
}

// Strictly typed via styled.d.ts
export const theme: DefaultTheme = {
  colors: {
    BLUE: '#2B5EDE',
    BLUE_LIGHT: '#6086E6',
    BLUE_LIGHTER: '#DCE4F9',
    BLUE_DARK: '#193F9F',
    BLUE_DARKER: '#0E2458',
    RED: '#DD4045',
    RED_LIGHT: '#ED979A',
    RED_LIGHTER: '#F9DCDD',
    RED_DARK: '#C82328',
    RED_DARKER: '#791519',
    GREEN: '#1C9B40',
    GREEN_LIGHT: '#26CF56',
    GREEN_LIGHTER: '#CBF6D7',
    GREEN_DARK: '#13672B',
    GREEN_DARKER: '#0D451D',
    ORANGE: '#E56910',
    ORANGE_LIGHT: '#F8A363',
    ORANGE_LIGHTER: '#FCDDC5',
    ORANGE_DARK: '#BF570D',
    ORANGE_DARKER: '#612D05',
    PINK: '#F4216F',
    PINK_LIGHT: '#F47AA7',
    PINK_LIGHTER: '#F4C3D5',
    PINK_DARK: '#CC1C5D',
    PINK_DARKER: '#660E2E',
    GREY: '#9EA5B2',
    GREY_LIGHT: '#DBDFE6',
    GREY_LIGHTER: '#F3F4F7',
    GREY_DARK: '#586176',
    GREY_DARKER: '#222831',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    OFFWHITE: '#F8F8F8',
    TRANSPARENT: '#00000000',
  },
  overlay: {
    DARK: 'rgba(34, 40, 49, 0.8)',
    MEDIUM: 'rgba(34, 40, 49, 0.56)',
    LIGHT: 'rgba(34, 40, 49, 0.32)',
  },

  /**
   * These are to be used anywhere the application that
   * margin or padding is being applied. No other
   * value should be used.
   */
  spacing: {
    S2: '2px',
    S4: '4px',
    S8: '8px',
    S12: '12px',
    M16: '16px',
    M24: '24px',
    M32: '32px',
    L40: '40px',
    L48: '48px',
    L56: '56px',
    XL64: '64px',
    XL72: '72px',
    XL80: '80px',
  },
  borderRadius: {
    default: '4px',
    large: '8px',
  },
  fontWeight: {
    regular: 400,
    semibold: 600,
    bold: 700,
  },
  fontSize: {
    S10: 'font-size: 10px; line-height: 24px;',
    S12: 'font-size: 12px; line-height: 24px;',
    M14: 'font-size: 14px; line-height: 24px;',
    M16: 'font-size: 16px; line-height: 24px;',
    L18: 'font-size: 18px; line-height: 24px;',
    L20: 'font-size: 20px; line-height: 24px;',
    L24: 'font-size: 24px; line-height: 24px;',
    B10: 'font-size: 10px; line-height: 16px;',
    B12: 'font-size: 12px; line-height: 16px;',
    B14: 'font-size: 14px; line-height: 16px;',
    B16: 'font-size: 16px; line-height: 16px;',
    B18: 'font-size: 18px; line-height: 24px;',
    H24: 'font-size: 24px; line-height: 32px;',
    H32: 'font-size: 32px; line-height: 40px;',
    H40: 'font-size: 40px; line-height: 48px;',
  },
  shadow: {
    CONTAINER: '0 1px 4px 0 rgba(88, 97, 118, 0.16)',
    CONTAINER_INVERT: '0 -1px 4px 0 rgba(88, 97, 118, 0.12)',
    LARGE: '0 2px 8px 0 rgba(88, 97, 118, 0.12)',
    XLARGE: '0px 2px 8px rgba(96, 134, 230, 0.24)',
  },
  // TODO: add remaining z-indices
  zIndices: {
    SEARCH_CONTAINER_OVERLAY: 9009,
    SEACH_CONTAINER_INPUT: 9010,
    MODAL: 9950,
    HEADER: 9008,
    DIALOG: 9900,
  },
  getInnerShadow: (color) => {
    const { r, g, b } = convertHexToRgb(color);
    return `inset 0 1px 2px 0 rgba(${r}, ${g}, ${b}, .4)`;
  },
  getOuterShadow: (color) => {
    const { r, g, b } = convertHexToRgb(color);
    return `0 1px 4px 0 rgba(${r}, ${g}, ${b}, .24)`;
  },
  getRgb: (color) => convertHexToRgb(color),
  windowInnerHeight: () => getWindowInnerHeight(),
};
