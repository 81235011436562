import { IDidomiObject } from '@didomi/react';
import { useState } from 'react';
import { PURPOSES, VENDORS } from 'types/didomi';
import { ENVIRONMENT_NAME } from 'utils';

type UseDidomiProps = {
  initialFunctionalConsent: boolean;
};

function useDidomi(props: UseDidomiProps) {
  const initialState = {
    functionalConsent: props.initialFunctionalConsent,
    brazeConsent: false,
    raygunConsent: false,
    wootricConsent: false,
    googleConsent: false,
    convertConsent: false,
  };

  const [consent, setConsent] = useState(initialState);

  const purposesRequiredForFunctionalConsent = [PURPOSES.FUNCTIONAL];

  const consentChecker = (
    purposesAvailable: string[],
    purposesRequiredForConsent: string[],
  ) =>
    purposesRequiredForConsent.every((purposeRequiredForConsent) =>
      purposesAvailable.includes(purposeRequiredForConsent),
    );

  const handleConsentUpdate = (purposes: string[]) => {
    const functionalConsent = consentChecker(
      purposes,
      purposesRequiredForFunctionalConsent,
    );

    const brazeConsent = !!checkVendorConsentStatus(VENDORS.BRAZE);
    const raygunConsent = !!checkVendorConsentStatus(VENDORS.RAYGUN);
    const wootricConsent = !!checkVendorConsentStatus(VENDORS.WOOTRIC);
    const googleConsent = !!checkVendorConsentStatus(VENDORS.GOOGLE);
    const convertConsent = !!checkVendorConsentStatus(VENDORS.CONVERT);

    setConsent({
      brazeConsent,
      functionalConsent,
      raygunConsent,
      wootricConsent,
      googleConsent,
      convertConsent,
    });
  };

  const checkVendorConsentStatus = (vendorId: string) => {
    if (typeof window !== 'undefined') {
      const consent = window.Didomi.getUserConsentStatusForVendor(vendorId);
      return consent;
    } else {
      return false;
    }
  };

  const onReady = (Didomi: IDidomiObject) => {
    const enabledPurposes =
      // Didomi type is missing "global" property on "purposes"
      //@ts-ignore
      Didomi.getUserStatus().purposes.global.enabled;
    handleConsentUpdate(enabledPurposes);
  };

  const onConsentChanged = () => {
    if (typeof window.Didomi !== 'undefined') {
      const enabledPurposes = window.Didomi.getUserStatus();
      //@ts-ignore
      handleConsentUpdate(enabledPurposes.purposes.global.enabled);
    }
  };

  const didomiConfig = {
    user: {
      bots: {
        /**
         * Indicate whether consent is required for bots
         * Defaults to true
         */
        consentRequired: false,
      },
    },
    // https://developers.didomi.io/cmp/web-sdk/consent-notice/cookies#storage-sources-for-user-information
    cookies: {
      storageSources: {
        cookie: true,
        localStorage: false,
      },
    },
  };

  return {
    didomiConfig,
    onReady,
    onConsentChanged,
    functionalConsent: consent.functionalConsent,
    allowBraze: consent.brazeConsent,
    allowRaygun: consent.raygunConsent && ENVIRONMENT_NAME !== 'dev',
    allowWootric: consent.wootricConsent && ENVIRONMENT_NAME === 'production',
    allowGoogle: consent.googleConsent,
    allowConvert: consent.convertConsent,
    consentChecker,
  };
}

export { useDidomi };
