/**
 * The data object associated with an action should reflect the input parameters for the corresponding function here:
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/raygun4js/index.d.ts#L177
 */
type SendData = {
  error: Error;
  tags?: string[];
  message?: string;
  customData?: Record<string, unknown>;
};

type TrackEventData = {
  type: string;
  path: string;
};

/**
 * The Raygun4JS client library to send tracking events to Raygun4JS for monitoring frontend performance and tracking errors / logs.
 * @param key - The action to perform, e.g., 'trackEvent'.
 * @param value - The object of event, e.g., `{ metadata: { state: isLoading: true }}`.
 */
function rg4js(key: 'send', value: SendData): void;
function rg4js(key: 'trackEvent', value: TrackEventData): void;
function rg4js(
  key: 'send' | 'trackEvent',
  value: SendData | TrackEventData,
): void {
  if ((window as any).rg4js) {
    (window as any).rg4js(key, value);
  } else {
    console.error('rg4js is not available in the window object.');
  }
}

export { rg4js };
