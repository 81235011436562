import { useState, useEffect } from 'react';

const useNetworkStatusObserver = () => {
  const initialOnlineStatus: boolean =
    typeof window !== 'undefined' && window.navigator.onLine
      ? window.navigator.onLine
      : true;

  const [isOnline, setOnlineStatus] = useState<boolean>(initialOnlineStatus);

  const updateNetworkStatus = () => {
    setOnlineStatus(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetworkStatus);
    window.addEventListener('online', updateNetworkStatus);

    return () => {
      window.removeEventListener('offline', updateNetworkStatus);
      window.removeEventListener('online', updateNetworkStatus);
    };
  }, []);

  return { isOnline };
};

export { useNetworkStatusObserver };
