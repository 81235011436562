// TODO - replace this with cryto.randomUUID or require('crypto').randomUUID() on the server-side.
// @ts-nocheck (added due to ts number issues beginning on line 16)
function randomStringGenerator(
  a?: number, // placeholder
) {
  return a // if the placeholder was passed, return
    ? // a random number from 0 to 15
      (
        a ^ // unless b is 8,
        ((Math.random() * // in which case
          16) >> // a random number from
          (a / 4))
      ) // 8 to 11
        .toString(16) // in hexadecimal
    : // or otherwise a concatenated string:
      (
        [1e7] + // 10000000 +
        -1e3 + // -1000 +
        -4e3 + // -4000 +
        -8e3 + // -80000000 +
        -1e11
      ) // -100000000000,
        .replace(
          // replacing
          /[018]/g, // zeroes, ones, and eights with
          randomStringGenerator, // random hex digits
        );
}

// example output: 9ef217a0-68fc-44db-a3a0-a05883bd08fc

// Credit: Jed Schmidt at https://gist.github.com/jed/982883

export { randomStringGenerator };
