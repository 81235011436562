import { useEffect } from 'react';
import { signOut, useSession } from 'next-auth/react';
import router from 'next/router';

import { useUserContext } from 'contexts/UserContext';

import { getCurrentTimeInSeconds } from 'utils/Time';
import { userLogout } from 'helpers/Logout';
import { isProtectedRoute } from 'constants/protectedRoutes';
import { ENVIRONMENT_NAME } from 'utils';
import { PAGE } from 'helpers/pages';
import { randomStringGenerator } from 'helpers/randomStringGenerator';
import { generateDDRecommendedUserCookie } from 'utils/ddRecommendedUserCookie';

interface RefreshTokenHandlerProps {
  setRefetchInterval: (interval: number) => void;
}

const RefreshTokenHandler = (props: RefreshTokenHandlerProps) => {
  const { setRefetchInterval } = props;
  const { data: session } = useSession();
  const currentTimeInSeconds = getCurrentTimeInSeconds();

  const {
    dispatchUser,
    user,
    consentOptions: { functionalConsent },
  } = useUserContext();

  const username = user?.name || '';

  const handleLegacyLogout = async () => {
    if (functionalConsent) {
      document.cookie = generateDDRecommendedUserCookie(
        randomStringGenerator(),
      );
    }
    if (ENVIRONMENT_NAME === 'dev') {
      router.push(`/${PAGE.LOCAL_DEV_SIGNOUT}`);
    } else {
      await userLogout(dispatchUser, username);
    }
  };

  const handleTokenExpiry = (callbackUrl?: string) => {
    handleLegacyLogout();

    signOut(callbackUrl ? { callbackUrl } : { redirect: false });
  };

  const updateRefetchInterval = () => {
    if (!!session && typeof session.expiresAt === 'number') {
      const timeRemaining = session.expiresAt - currentTimeInSeconds;
      const refetchInterval = timeRemaining > 0 ? timeRemaining : 0;
      setRefetchInterval(refetchInterval);
    }
  };

  const handleTokenError = () => {
    if (
      session?.error === 'RefreshAccessTokenError' ||
      session?.expiresAt === null
    ) {
      if (isProtectedRoute(location.pathname)) {
        handleTokenExpiry('/');
      } else {
        handleTokenExpiry();
      }
    }
  };

  useEffect(() => {
    updateRefetchInterval();
    handleTokenError();
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return null;
};

export default RefreshTokenHandler;
