// To match our grid: https://get.foundation/sites/docs/media-queries.html
interface Media {
  smallOnly: string;
  medium: string;
  mediumOnly: string;
  large: string;
  mediumWithHeight: (height: number) => string;
  largeWithHeight: (height: number) => string;
  xLarge: string;
}

export const media: Media = {
  smallOnly: '@media screen and (max-width: 39.9375em)',
  medium: '@media screen and (min-width: 40em)',
  mediumWithHeight: (height) =>
    `@media screen and (min-width: 40em) and (max-height: ${height}px)`,
  mediumOnly: '@media screen and (min-width: 40em) and (max-width: 63.9375em)',
  large: '@media screen and (min-width: 64em)',
  largeWithHeight: (height) =>
    `@media screen and (min-width: 64em) and (max-height: ${height}px)`,
  xLarge: `@media screen and (min-width: 75em)`,
};

export function matchMedia(mediaQuery: string) {
  // remove @media from the string
  const parsedMediaQuery = mediaQuery.replace('@media ', '');
  return typeof window !== 'undefined'
    ? window.matchMedia(parsedMediaQuery).matches
    : true;
}
// 60em / 960px
export const SCREEN_LARGE = 60 * 16;
// 64em / 1024px
export const SCREEN_XLARGE = 64 * 16;
// 40em / 640px
export const SCREEN_MEDIUM = 40 * 16;
