/**
 * <button> and <a> can both be styled to be a button.
 * These shared styles ensure that we only need to make
 * a change in one place along with types.
 */
import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import { ThemeTypes } from 'types/Theme.namespace';
import type { NextLinkProps } from 'types';

export type LinkType = 'PRIMARY' | 'SECONDARY' | 'TERTIARY' | 'INVERTED';

interface CommonProps {
  ofType?: LinkType;
  fontSize?: ThemeTypes.FontSize;
  colorVariant?: ThemeTypes.Colors;
  underline?: boolean;
  inline?: boolean;
  centerAll?: boolean;
  disabled?: boolean;
}

export interface LinkButtonProps
  extends CommonProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {}

export interface LinkProps
  extends CommonProps,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  isFakeLink?: boolean;
  NextLink?: NextLinkProps;
  shallow?: boolean;
}

export const getColour = (
  theme: DefaultTheme,
  ofType?: LinkType,
  state?: 'ACTIVE' | 'HOVER' | 'FOCUS',
) => {
  switch (ofType) {
    case 'SECONDARY':
      switch (state) {
        case 'ACTIVE':
        case 'HOVER':
          return theme.colors.GREY_DARK;
        default:
          return theme.colors.GREY_DARKER;
      }
    case 'TERTIARY':
      switch (state) {
        case 'ACTIVE':
        case 'HOVER':
          return theme.colors.GREY_DARKER;
        default:
          return theme.colors.GREY_DARK;
      }
    case 'INVERTED':
      switch (state) {
        case 'ACTIVE':
        case 'HOVER':
          return theme.colors.GREY_LIGHT;
        default:
          return theme.colors.GREY_LIGHTER;
      }
    default:
      switch (state) {
        case 'ACTIVE':
          return theme.colors.BLUE_DARK;
        case 'HOVER':
          return theme.colors.BLUE_DARKER;
        default:
          return theme.colors.BLUE;
      }
  }
};

const getColorVariant = (
  colorVariant: ThemeTypes.Colors,
  theme: DefaultTheme,
  disabled?: boolean,
) => {
  let color: ThemeTypes.Colors = 'BLUE_DARK';
  if (colorVariant === 'GREY_DARK') {
    color = 'GREY';
  }
  if (colorVariant === 'GREY_LIGHT') {
    color = 'GREY_LIGHTER';
  }
  if (colorVariant === 'GREY') {
    color = 'GREY_LIGHT';
  }
  if (colorVariant === 'WHITE') {
    color = 'WHITE';
  }
  if (disabled) {
    color = colorVariant;
  }
  return theme.colors[color];
};

export const LinkCSS = css<CommonProps>`
  ${({ theme, fontSize }) =>
    fontSize !== undefined ? theme.fontSize[fontSize] : theme.fontSize.M14};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme, ofType, colorVariant }) => {
    if (!colorVariant) {
      return getColour(theme, ofType);
    } else if (colorVariant) {
      return theme.colors[colorVariant];
    }
  }};
  cursor: pointer;
  display: ${({ inline }) => (inline && 'inline') || 'block'};
  padding: 0;

  ${({ theme, underline }) =>
    underline &&
    `
    text-decoration: underline;
    text-underline-offset: ${theme.spacing.S4};
`};

  ${({ inline }) =>
    inline &&
    `
    font-size: inherit;
    line-height: inherit;
  `};

  ${({ centerAll }) =>
    centerAll ? `display: flex; align-items: center;` : ''};

  &:hover,
  &:focus {
    ${({ theme, colorVariant, disabled }) =>
      !disabled &&
      `
      text-decoration: underline;
      text-underline-offset: ${theme.spacing.S4};
      ${colorVariant ? `color: ${getColorVariant(colorVariant, theme)};` : ''}
      `}
  }

  &:hover {
    color: ${({ theme, ofType, colorVariant, disabled }) => {
      if (!colorVariant && !disabled) {
        return getColour(theme, ofType, 'HOVER');
      } else if (colorVariant) {
        getColorVariant(colorVariant, theme, disabled);
      }
    }};
  }

  &:active {
    color: ${({ theme, ofType, colorVariant, disabled }) => {
      if (!colorVariant && !disabled) {
        return getColour(theme, ofType, 'ACTIVE');
      } else if (colorVariant) {
        getColorVariant(colorVariant, theme, disabled);
      }
    }};
  }

  &.focus-visible {
    border-width: 2px;
    border-style: solid;
    border-radius: ${({ theme }) => theme.borderRadius.default};
    border-color: transparent;
    outline: 2px solid
      ${({ theme, ofType }) =>
        ofType === 'INVERTED' ? theme.colors.GREY_LIGHTER : theme.colors.BLUE};
    outline-offset: 0;
    color: ${({ theme, ofType, colorVariant, disabled }) => {
      if (!colorVariant && !disabled) {
        return getColour(theme, ofType, 'FOCUS');
      } else if (colorVariant) {
        getColorVariant(colorVariant, theme);
      }
    }};
  }

  &:active &:focus:not(.focus-visible) {
    outline: none;
  }

  ${({ disabled, theme }) =>
    disabled ? `cursor: not-allowed; color: ${theme.colors.GREY}` : ''};
`;
