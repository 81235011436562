export const protectedRoutes = [
  '/publish',
  '/publish-new',
  '/update',
  '/dashboard/',
  '/order/',
  '/unsubscribe/',
];

export function isProtectedRoute(path: string) {
  for (let i = 0; i < protectedRoutes.length; i++) {
    if (path.startsWith(protectedRoutes[i])) {
      return true;
    }
  }
}
