import type { ToastOptions } from 'react-toastify';
import styled from 'styled-components';

import { Notification } from 'components/Toolkit/Notification/Notification';
import type { NotificationProps } from 'components/Toolkit/Notification/Notification.typed';

/**
 * Future improvement:
 * - Replace type with intent
 * - Replace text with children
 */
export interface ToastProps
  extends Omit<NotificationProps, 'intent' | 'children'>,
    Omit<ToastOptions, 'className' | 'onClose' | 'type'> {
  type: NotificationProps['intent'];
  text: NotificationProps['children'];
  closeOnClick?: boolean;
  customId?: number | string;
  autoClose?: number;
}

const SNotification = styled(Notification)`
  max-width: 320px;
  // Included to account for absolute positioned close button
  padding-right: ${({ theme }) => theme.spacing.L40};
`;

export const fireToast = async ({
  type,
  text,
  hasIcon = true,
  closeOnClick = true,
  customId,
  autoClose = 5000,
  className,
  iconOverride,
  isClosable,
  mb,
  onClose,
  ...rest
}: ToastProps) => {
  import(/* webpackChunkName: "fireToast" */ 'react-toastify').then(
    ({ toast }) => {
      const options: ToastOptions = {
        ...rest,
        className,
        autoClose,
        closeOnClick,
        onClose,
      };
      return toast(
        <SNotification
          hasIcon={hasIcon}
          intent={type}
          className={className}
          iconOverride={iconOverride}
          isClosable={isClosable}
          mb={mb}
          onClose={onClose}
        >
          {text}
        </SNotification>,
        {
          toastId: customId,
          ...options,
        },
      );
    },
  );
};
