import { jwtDecode } from 'jwt-decode';

interface DecodeJWTArgs {
  token: string;
  onError: (error: any) => void;
}

const decodeJWT = <T>({ token, onError }: DecodeJWTArgs) => {
  try {
    const decodedDidomiToken = jwtDecode<T>(token, {
      header: true,
    });

    return decodedDidomiToken;
  } catch (error) {
    onError(error);
  }
};

export { decodeJWT };
