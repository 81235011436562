/**
 * A simple implementation of sessionStorage which uses JSON APIs
 * to encode and decode JSON objects.
 * */

const SmartSessionStorage = {
  set(name: string, value: any) {
    if (!window) throw Error('Method can only be used on client');
    window.sessionStorage.setItem(name, JSON.stringify(value));
  },
  get(name: string) {
    if (!window) throw Error('Method can only be used on client');
    const value = window.sessionStorage.getItem(name);
    try {
      return value ? JSON.parse(value) : null;
    } catch (e) {
      console.error(
        `JSON parse getting localStorage item: "${name}" caused an error`,
        e,
      );
      return null;
    }
  },
  remove(name: string) {
    if (!window) throw Error('Method can only be used on client');
    window.sessionStorage.removeItem(name);
  },
  clear() {
    if (!window) throw Error('Method can only be used on client');
    window.sessionStorage.clear();
  },
};

export { SmartSessionStorage };
