import type { IncomingMessage } from 'http';

export function getTLD(req?: IncomingMessage) {
  let hostname = '';
  if (typeof window !== 'undefined') {
    // In the browser, get the hostname from window.location
    hostname = window.location.hostname;
  } else {
    // In staging and production a header has been set with the tld
    // so we will use that
    if (req?.headers['x-tld']?.length) {
      return req?.headers['x-tld'];
    }
    // Otherwise on the server, get the hostname from the request headers.
    // We use the host header which is available on IncomingMessage.
    // https://nodejs.org/api/http.html#http_class_http_incomingmessage
    // But the host header may include the port so first we take that off, if it exists.
    hostname = req?.headers.host?.split(':')[0] || '';
  }
  const tld = hostname.split('.').pop();

  if (tld === 'localhost') {
    return '';
  }

  if (tld == 'internal') {
    return '.local';
  }

  if (tld === 'uk') {
    return '.co.uk';
  }

  if (tld === 'build') {
    return '';
  }

  return `.${tld}`;
}
