import { toast } from 'react-toastify';

import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { useNetworkStatusObserver } from 'hooks/UseNetworkStatusObserver';

import { Link } from 'components/Toolkit/Button/Link';

import { fireToast } from 'helpers/Toasts';

const useOnNetworkConnectionStatusChange = () => {
  const { isOnline } = useNetworkStatusObserver();

  const OFFLINE_ERROR_TOAST_ID = 'offline-error-toast';
  const ONLINE_SUCCESS_TOAST_ID = 'online-success-toast';

  const { isActive, dismiss } = toast;

  const onOffline = () => {
    if (!isActive(OFFLINE_ERROR_TOAST_ID)) {
      if (isActive(ONLINE_SUCCESS_TOAST_ID)) dismiss(ONLINE_SUCCESS_TOAST_ID);
      fireToast({
        type: 'ERROR',
        text: (
          <>
            No internet connection
            {typeof window !== 'undefined' ? (
              <>
                ?&nbsp;
                <Link
                  ofType="SECONDARY"
                  href={window.location.href}
                  inline
                  underline
                >
                  Please refresh your page
                </Link>
              </>
            ) : (
              ''
            )}
          </>
        ),
        customId: OFFLINE_ERROR_TOAST_ID,
      });
    }
  };

  const onOnline = () => {
    if (!isActive(ONLINE_SUCCESS_TOAST_ID)) {
      if (isActive(OFFLINE_ERROR_TOAST_ID)) dismiss(OFFLINE_ERROR_TOAST_ID);
      fireToast({
        type: 'SUCCESS',
        text: 'Your internet connection has been restored',
        customId: ONLINE_SUCCESS_TOAST_ID,
      });
    }
  };

  useOnUpdateOnly(() => {
    isOnline ? onOnline() : onOffline();
  }, [isOnline]);
};

export { useOnNetworkConnectionStatusChange };
