import { TOrderTracking } from 'domains/Order';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { BRAZE_API_KEY, BRAZE_SDK_ENDPOINT },
} = getConfig();

const brazeService = () => {
  import(
    /* webpackExports: ["initialize", "openSession", "requestPushPermission", "subscribeToInAppMessage", "isPushSupported", "isPushPermissionGranted", "isPushBlocked", "showInAppMessage", "FullScreenMessage", "logCustomEvent"] */
    '@braze/web-sdk'
  ).then(
    ({
      initialize,
      openSession,
      requestPushPermission,
      subscribeToInAppMessage,
      isPushSupported,
      isPushPermissionGranted,
      isPushBlocked,
      showInAppMessage,
      logCustomEvent,
      FullScreenMessage,
    }) => {
      initialize(BRAZE_API_KEY, {
        baseUrl: BRAZE_SDK_ENDPOINT,
        allowUserSuppliedJavascript: true,
        safariWebsitePushId: 'web.ie.distilled.donedeal',
        // Set to true only when debugging
        enableLogging: false,
      });
      subscribeToInAppMessage(function (inAppMessage) {
        // check if message is not a control variant
        if (inAppMessage instanceof FullScreenMessage) {
          // access the key-value pairs, defined as `extras`
          const keyValuePairs = inAppMessage.extras || {};
          // check the value of our key `msg-id` defined in the Braze dashboard
          if (keyValuePairs['msg-id'] === 'push-primer') {
            // We don't want to display the soft push prompt to users on browsers
            // that don't support push, or if the user has already granted/blocked permission
            if (
              isPushSupported() === false ||
              isPushPermissionGranted() ||
              isPushBlocked()
            ) {
              // do not call `showInAppMessage`
              return;
            }

            // user is eligible to receive the native prompt
            // register a click handler on one of the two buttons
            if (inAppMessage.buttons[0]) {
              // Prompt the user when the first button is clicked
              inAppMessage.buttons[0].subscribeToClickedEvent(function () {
                requestPushPermission();
              });
            }
          }
        }
        // show the in-app message now
        showInAppMessage(inAppMessage);
      });
      openSession();
      logCustomEvent('prime-for-push');
    },
  );
};

const changeUser = (userId: string) => {
  import(
    /* webpackExports: ["changeUser"] */
    '@braze/web-sdk'
  ).then(({ changeUser }) => {
    changeUser(userId.toString());
  });
};

enum Event {
  SAVE_AD = 'Save Ad',
  PLACE_AD = 'Place Ad',
  EDIT_AD = 'Edit Ad',
  SEND_MESSAGE = 'Send Message',
  SEND_DEALER_ENQUIRY = 'Send Dealer Enquiry',
  REVEAL_PHONE = 'Reveal Phone',
  RELIST_AD = 'Relist Ad',
}

type TLogEvent<T = Event> = {
  name: T;
  properties: TEventProperties<T>;
};

type TEventProperties<T = Event> = T extends Event.SAVE_AD
  ? TSaveAdEventProperties
  : T extends Event.PLACE_AD
  ? TPlaceAdEventProperties
  : T extends Event.EDIT_AD
  ? TEditAdEventProperties
  : T extends Event.SEND_MESSAGE
  ? TSendMessageEventProperties
  : T extends Event.SEND_DEALER_ENQUIRY
  ? TSendDealerEnquiryEventProperties
  : T extends Event.REVEAL_PHONE
  ? TRevealPhoneEventProperties
  : T extends Event.RELIST_AD
  ? TRelistAdEventProperties
  : never;

type TSaveAdEventProperties = {
  Platform: 'Web';
  subsection?: string;
  'seller type'?: string;
};

type TPlaceAdEventProperties = {
  ad_id?: string;
  category?: string;
  location?: string;
  platform?: string | null;
  price?: string;
};

type TEditAdEventProperties = {
  ad_id: number;
  category: string;
  location: string;
  platform: 'Web';
  price: string;
};

type TSendMessageEventProperties = {
  Platform: 'Web';
  subsection?: string;
};

type TSendDealerEnquiryEventProperties = {
  Platform: 'Web';
  subsection?: string;
};

type TRevealPhoneEventProperties = {
  Platform: 'Web';
  subsection?: string;
  sellertype?: string;
};

type TRelistAdEventProperties = {
  Platform: 'Web';
  subsection?: string;
};

const logEvent = <T extends string>({ name, properties }: TLogEvent<T>) => {
  import(
    /* webpackExports: ["logCustomEvent"] */
    '@braze/web-sdk'
  ).then(({ logCustomEvent }) => {
    logCustomEvent(name, properties);
  });
};

const logBrazeSaveAdEvent = (subsection?: string, sellerType?: string) => {
  logEvent({
    name: Event.SAVE_AD,
    properties: {
      Platform: 'Web',
      subsection: subsection,
      'seller type': sellerType,
    },
  });
};

const logBrazePlaceAdEvent = (orderTrackingData: TOrderTracking) => {
  if (orderTrackingData) {
    const adId = orderTrackingData.adId?.toString();
    const category = orderTrackingData.adSection?.toString();
    const location = orderTrackingData.county?.toString();
    const price = orderTrackingData.price.toString();
    logEvent({
      name: Event.PLACE_AD,
      properties: {
        platform: 'Web',
        ad_id: adId,
        category: category,
        location: location,
        price: price,
      },
    });
  }
};

const logBrazeEditAdEvent = (
  adId: number,
  subsection: string,
  location: string,
  price: string,
) => {
  logEvent({
    name: Event.EDIT_AD,
    properties: {
      platform: 'Web',
      ad_id: adId,
      category: subsection,
      location: location,
      price: price,
    },
  });
};

const logBrazeSendMessageEvent = (subsection?: string) => {
  logEvent({
    name: Event.SEND_MESSAGE,
    properties: {
      Platform: 'Web',
      subsection: subsection,
    },
  });
};

const logBrazeSendDealerEnquiryEvent = (subsection?: string) => {
  logEvent({
    name: Event.SEND_DEALER_ENQUIRY,
    properties: {
      Platform: 'Web',
      subsection: subsection,
    },
  });
};

const logBrazeRevealPhoneEvent = (subsection?: string, sellerType?: string) => {
  logEvent({
    name: Event.REVEAL_PHONE,
    properties: {
      Platform: 'Web',
      subsection: subsection,
      sellertype: sellerType,
    },
  });
};

const logBrazeRelistAdEvent = (subsection: string) => {
  logEvent({
    name: Event.RELIST_AD,
    properties: {
      Platform: 'Web',
      subsection: subsection,
    },
  });
};

export {
  brazeService,
  logBrazeSaveAdEvent,
  logBrazePlaceAdEvent,
  logBrazeEditAdEvent,
  logBrazeSendMessageEvent,
  logBrazeSendDealerEnquiryEvent,
  logBrazeRevealPhoneEvent,
  logBrazeRelistAdEvent,
  changeUser,
};
