import type { DidomiToken, PURPOSES } from 'types/didomi';
import { decodeJWT } from 'utils/DecodeJWT';

interface GetConsentArgs {
  token: string;
  purpose: PURPOSES;
  onError: (error?: any) => void;
}

const getConsent = (props: GetConsentArgs) => {
  const { purpose, ...rest } = props;

  const decodedDidomiToken = decodeJWT<DidomiToken>({ ...rest });

  const enabledPurposes = decodedDidomiToken?.purposes
    ? decodedDidomiToken?.purposes.enabled
    : [];

  const consentToPurpose = enabledPurposes
    ? enabledPurposes.includes(purpose)
    : false;

  return consentToPurpose;
};

export { getConsent };
